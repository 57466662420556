<template>
  <base-view title="Edificios" icon="home">
    <div>
      <check-authorization :requiresAuthorizations="['indice edificios']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Edificios

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear edificios']"
                :overrideIf="$store.getters['edificioModule/isEditingResource']"
              >
                <edificio-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <edificio-list @on-got-item="showForm = true"></edificio-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import EdificioForm from '@/components/Catalogos/Edificios/EdificioForm'
import EdificioList from '@/components/Catalogos/Edificios/EdificioList'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'edificioModule'

export default {
  name: 'EdificiosView',

  components: {
    EdificioForm,
    EdificioList
  },

  async created () {
    await this.$store.dispatch('subcondominioModule/getResource')
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
